﻿import { getEnvironment } from 'xcel-env';

function getContentClientEnv() {
  switch (getEnvironment()) {
    case 'uat':
      return '.net';
    case 'live':
      return '.com';
  }

  return '-test.net';
}

export function init(): void {
  window['contentClientUrl'] = `https://content.rewardstation${getContentClientEnv()}/contentClientEntry.js`;
}
